

















































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    eventConfig: {
      type: Boolean,
      required: false,
      default: false,
    },
    eventConfigData: {
      type: Boolean,
      required: false,
      default: false,
    },
    eventConfigParticipantGroups: {
      type: Boolean,
      required: false,
      default: false,
    },
    eventConfigDiscounts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
